class ImageSupport {
  constructor() {
    this.imageFormats = [
      {
        className: 'webp',
        format: 'image/webp',
      },
    ];
  }

  /**
   * Sets image formats
   *
   * imageFormats should look something like this:
   * [
   *   {
   *     className: 'name',
   *     format: 'image/name',
   *   },
   * ]
   *
   * @param {Object[]} imageFormats
   */
  setImageFormats(imageFormats) {
    this.imageFormats = imageFormats;
  }

  /**
   * Sets classes based on imageFormats. It wil set one of these classes to $element:
   *
   * 'classname'    - Format IS supported by browser
   *
   * 'no-classname' - Format IS NOT supported by browser
   *
   * @param {HTMLElement|Element} element
   */
  setSupportedClasses(element) {
    this.imageFormats.forEach((imageFormat) => {
      if (true === this.constructor.supportsImageFormat(imageFormat.format)) {
        element.classList.add(imageFormat.className);
      } else {
        element.classList.add(`no-${imageFormat.className}`);
      }
    });
  }

  /**
   * Check if browser supports image format
   *
   * @param {String} format
   *
   * @return {boolean}
   */
  static supportsImageFormat(format) {
    const elem = document.createElement('canvas');

    if (null !== (elem.getContext && elem.getContext('2d'))) {
      // was able or not to get format representation
      return 0 === elem.toDataURL(format).indexOf(`data:${format}`);
    }
    // very old browser like IE 8, canvas not supported
    return false;
  }
}

module.exports = ImageSupport;
